import React from "react";
import ImageContainer from "./ImageContainer";

const FrontSide = () => {
  return (
    <>
          <main class="flex-grow w-full text-Themewhite  flex flex-col justify-center items-center  ">
            <h1 className="text-4xl md:text-[3rem] leading-normal text-center">
            "High-Performing Teams of Expertly Vetted{" "}
              <span className="text-teal-400">Software Engineers"</span>,
            </h1>
            <div className="w-full max-w-[24rem]">
            <ImageContainer />
            </div>
            <p class="text-white text-lg text-center mt-8  md:max-w-[70%]">
              Scale your team up and down with the top 10% talent skilled in
              React JS, Next JS, Node.js, .NET and AWS.
            </p>
            <button
              type="button"
              class="bg-teal-400 text-ThemeBlack uppercase hover:bg-teal-500 focus:ring-4 font-medium w-full sm:w-80 py-2 mt-8 "
            >
              Get a proposal
            </button>
          </main>
    </>
  );
};

export default FrontSide;
