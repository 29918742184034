import { FaLinkedin, FaBehance, FaTwitter, FaGithub } from "react-icons/fa";

const infoCss = "hover:underline decoration-themeTeal text-sm";
const titleCss = "mb-6 text-sm font-semibold uppercase text-themeTeal";
const iconCss = "w-4 h-4";

// Dynamic Data Array
const data = [
  {
    category: 'Services',
    items: [
      { name: 'Web Development', url: '#' },
      { name: 'Mobile App Development', url: '#' },
      { name: 'Desktop App Development', url: '#' },
      { name: 'UX/UI Design', url: '#' }
    ]
  },
  {
    category: 'Technologies',
    items: [
      { name: 'React JS', url: '#' },
      { name: 'Next JS', url: '#' },
      { name: 'Node JS', url: '#' },
      { name: '.NET', url: '#' },
      { name: 'Typescript', url: '#' },
      { name: 'React Native', url: '#' },
      { name: 'AWS', url: '#' }
    ]
  },
  {
    category: 'Industries',
    items: [
      { name: 'Fintech', url: '#' },
      { name: 'Banking', url: '#' },
      { name: 'Accounting', url: '#' }
    ]
  }
];

const Footer = () => {
  return (
    <footer className="bg-ThemeBlack">
      <div className="mx-auto w-full max-w-screen-xl p-4 pt-10 py-6 lg:py-8 text-Themewhite">
        <div className="md:flex md:justify-between gap-5">
          <div className="mb-20 md:mb-0 max-w-[26rem]">
            <h1 className="text-xl lg:4xl font-bold Logo-font-family text-themeTeal">22signals</h1>
            <p className="mt-4">
              Leverage the Top 10% of Elite Developers Specializing in React, Node.js, .NET, and AWS for Superior JavaScript Solutions – Onboard in Just 10 Days!
            </p>

            <div className="flex mt-10">
              <a href="#" className={infoCss}>
                <FaLinkedin className={iconCss} />
              </a>
              <a href="#" className={`${infoCss} ms-5`}>
                <FaBehance className={iconCss} />
              </a>
              <a href="#" className={`${infoCss} ms-5`}>
                <FaTwitter className={iconCss} />
              </a>
              <a href="#" className={`${infoCss} ms-5`}>
                <FaGithub className={iconCss} />
              </a>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            {/* Loop through data array to create footer sections */}
            {data.map((section, idx) => (
              <div key={idx}>
                <h2 className={titleCss}>{section.category}</h2>
                <ul>
                  {section.items.map((item, itemIdx) => (
                    <li key={itemIdx} className="mb-4">
                      <a href={item.url} className={infoCss}>
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2023 <a href="#" className="hover:underline">32Signals</a>. All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
