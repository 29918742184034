import React from 'react';

const ContactForm = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center p-6">
      <div className="w-full max-w-5xl flex">
        
        {/* Left Side - Form */}
        <div className="w-full p-8 rounded-lg">
          <h2 className="text-2xl font-semibold mb-6">Tell us about your project</h2>
          <form className="space-y-4">
            <div>
              <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                id="name"
                className="w-full p-3 border border-gray-300 rounded-md"
                required
              />
            </div>

            <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-700">Company Email</label>
              <input
                type="email"
                id="email"
                className="w-full p-3 border border-gray-300 rounded-md"
                required
              />
            </div>

            <div>
              <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-700">Phone Number (Optional)</label>
              <input
                type="tel"
                id="phone"
                className="w-full p-3 border border-gray-300 rounded-md"
              />
            </div>

            <div>
              <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-700">About my product (Optional)</label>
              <textarea
                id="message"
                rows="4"
                className="w-full p-3 border border-gray-300 rounded-md"
                placeholder="e.g. tech stack, scope, project timeline"
              ></textarea>
            </div>

            <div>
              <p className="text-sm text-gray-600 mb-2">I want to:</p>
              <div className="space-y-2">
                <label className="block">
                  <input type="radio" name="purpose" value="scale" required /> Scale my development team
                </label>
                <label className="block">
                  <input type="radio" name="purpose" value="build" required /> Build a digital product from scratch
                </label>
                <label className="block">
                  <input type="radio" name="purpose" value="modernize" required /> Modernize existing application
                </label>
                <label className="block">
                  <input type="radio" name="purpose" value="other" required /> Something else
                </label>
              </div>
            </div>

            <div>
              <label className="flex items-center">
                <input type="checkbox" className="mr-2" required />
                I consent to the processing of my personal data for marketing purposes.
              </label>
            </div>

            <button
              type="submit"
              className="w-full bg-blue-500 text-white p-3 rounded-md hover:bg-blue-600 transition"
            >
              Submit
            </button>
          </form>
        </div>

        {/* Divider */} 
        <div className="w-1 bg-gray-300 mx-4"></div>

        {/* Right Side */}
        <div className="w-1/2 flex items-center justify-center p-8">
          <p className="text-lg text-gray-700">P.S. In case you hate forms: <br /><a href="mailto:aman@21signals.com" className="text-blue-500 underline">aman@21signals.com</a></p>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
