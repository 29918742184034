import React from 'react';
import Layout from '../components/layouts/layout';
import FrontSide from '../components/hero section/FrontSide';
import SuggestionContainer from '../components/hero section/SuggestionContainer';
import OnboadingContainer from '../components/hero section/TellUsAboutProject';


const Home = () => {
  return (
    <Layout>
      <section className="w-[92%] lg:w-[60%] mx-auto py-20">
<FrontSide/>
      </section>
      <section className='w-[92%] md:w-[60%] lg:w-[50%] mx-auto py-20 relative overflow-hidden'
      >
  <SuggestionContainer />
</section>
<section className='bg-Themewhite py-20 px-4 md:px-16'>
<OnboadingContainer/>
</section>
    </Layout>
  );
};

export default Home;
