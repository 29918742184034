import React from "react";

const ImageContainer = () => {

  const img="https://www.creative-tim.com/learning-lab/tailwind-starter-kit/img/team-4-470x470.png"
  return (
    <>
     <div class="flex  gap-2 md:gap-5 items-center w-full">
    <span className="text-Themewhite text-[4rem] ">[</span>
    <section className="flex  h-full mt-5 w-full justify-between ">
  <img src={img} alt="" className="w-12 md:w-14  rounded-full"/>
  <img src={img} alt="" className="w-12 md:w-14  rounded-full"/>
  <img src={img} alt="" className="w-12 md:w-14  rounded-full"/>
  <img src={img} alt="" className="w-12 md:w-14  rounded-full"/>
    </section>
    <span className="text-Themewhite text-[4rem]">]</span>
</div>

    </>
  );
};

export default ImageContainer;
 